import React from "react";
import ViewSection from "components/ViewSection.js";
import ReadMore from "components/ReadMore.js";
import HealthPdf from "downloads/OUR WORK - CENTER ON HEALTHCARE.pdf";
import FoundationPdf from "downloads/OUR WORK - FOUNDATION.pdf";
import Image from "components/Image";
import FadeIn from "components/FadeIn.js";
import Research from "images/Research.png"

export default () => (
  <>
    <FadeIn>
      <div className="font--h2 text-align--center">Peter G. Peterson Foundation</div>
    </FadeIn>
    <FadeIn>
      <p>Building a vibrant American economy requires a strong fiscal foundation. Since 2008, the Peterson Foundation has worked to increase awareness of America&rsquo;s key fiscal challenges and to advance solutions that put our nation on a more sustainable fiscal path. </p>
      <p>As a non-partisan and non-profit organization, we work across party lines with policy experts, elected leaders and the general public. Our mission is to bring people together around a shared vision for a strong and sustainable fiscal and economic future with rising incomes, abundant opportunities and widely-shared prosperity for the next generation.</p>
      <p>The Foundation undertakes a range of activities, partnerships and grants to educate and engage our nation&rsquo;s leaders and the public in order to secure a better fiscal and economic path forward.</p>
    </FadeIn>
    <FadeIn>
      <div className="Columns">
        <Item
          heading="Research & Analysis"
          text="To help explain the fiscal challenge itself and illuminate the way forward, we produce timely research and analyses, adding depth and insight to the policy conversation."
          image={Research} />
        <Item
          heading="Educating & Engaging"
          text="We inspire and empower Americans with innovative campaigns that increase awareness, mobilize young people and drive a thoughtful national dialogue around sustainable fiscal and economic policies."
          image="/tenyearreport/assets/Educating & Engaging.png" />
        <Item
          heading="Advancing Solutions"
          text="America’s fiscal challenges are serious, but they are also solvable. We build bipartisan coalitions to develop and advance common sense solutions that cut through entrenched partisan lines."
          image="/tenyearreport/assets/Advancing Solutions.png" />
        <Item
          heading="Convening"
          text="We provide a platform for leading thinkers and policymakers to have constructive, substantive discussions – including through our signature annual event,   the Fiscal Summit."
          image="/tenyearreport/assets/Convening.png" />
      </div>
    </FadeIn>
    <ViewSection download={FoundationPdf} />

    <section id="healthcare">
      <div className="Section-line" />
      <div>
        <FadeIn>
          <div className="font--h2 text-align--center">Peterson Center on Healthcare</div>
        </FadeIn>
        <FadeIn>
          <p>In many ways, healthcare represents the single greatest issue for our nation&rsquo;s fiscal and economic future. To make meaningful progress on America&rsquo;s fiscal outlook and build a growing economy, we must address the performance of the U.S. healthcare delivery system. </p>
          <p>From a federal budget standpoint, healthcare is far and away the key driver of spending. In fact, healthcare accounts for approximately 70% of the growth in federal mandatory spending over the long term.</p>
          <p>Moreover, healthcare presents its own major economic challenge, as rising healthcare costs lower wages, hurt competitiveness, and threaten our ability to invest in education and other critical drivers of a prosperous future. </p>
        </FadeIn>
        <ReadMore>
          <p>At a total spend of approximately $3.7 trillion, or 18% of the national economy, the U.S. healthcare system is the most expensive in the world. However, our health outcomes are worse than those of many other nations. Americans often endure unnecessary, ineffective or even harmful treatments, lowering the quality of their lives.</p>

          <p>But healthcare is different from other fiscal challenges because it can&rsquo;t be solved with new budget policy in Washington alone. In order to address this problem, we need to improve the overall performance of how care is provided across this country. We need to deliver better outcomes at lower cost.</p>

          <p>Recognizing the urgent need to change course and the scale and unique nature of this challenge, the Peterson Center on Healthcare was established in 2014, with an initial commitment of $200 million from the Peterson Foundation. </p>

          <p>Our Center&rsquo;s work is driven by a simple strategy: find and validate the proven solutions that already exist and accelerate their adoption on a national scale. We work with a range of partners to foster a healthier environment for change, with better incentives, transparency and understanding. </p>
        </ReadMore>
        <FadeIn>
          <div className="Columns">
            <Item
              heading="High-Performance Healthcare"
              text="There are already many great examples of high-performance care – we just need to replicate them. Rather than serving as an inventor of new approaches, we seek what already works. We focus on finding, validating and scaling models proven to deliver higher-quality care and lower costs."
              image={"/tenyearreport/assets/Scaling Healthcare.png"} />
            <Item
              heading="Fostering an Environment for Improvement"
              text="Recognizing that context matters, we focus on a broad portfolio of work to improve the environment for productive, lasting change – including monitoring performance more closely, increasing data transparency and building coalitions of key stakeholders."
              image="/tenyearreport/assets/Charting Healthcare Performance.png" />
          </div>
        </FadeIn>
        <ViewSection download={HealthPdf} />
      </div>
    </section>
  </>
);

const Item = ({
  heading,
  text,
  image
}) => (
  <div className="Columns-item">
    <Image alt="" src={image} width="800" height="500" />
    <div className="Columns-item-heading font--h3">{heading}</div>
    <div>{text}</div>
  </div>
)