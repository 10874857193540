import React from "react";
import ReadMore from "components/ReadMore.js";
import Image from "components/Image.js";
import Quote from "components/Quote.js";
import ViewSection from "components/ViewSection"
import FounderPdf from "downloads/OUR FOUNDER.pdf";
import Peter from "images/Peter Peterson.jpg";
import PeterSummit from "images/Peter_Summit.jpg"
export default () => (
  <>
    <Image className="contain--small center-margin" src={Peter} alt="Peter Peterson" width="2085" height="2598" />
    <Quote>
      “We have <span className="highlight-text">a moral obligation to future generations</span> to build a dynamic, <span className="highlight-text">growing American economy</span> that is defined by opportunity, mobility and prosperity.”
    </Quote>
    <ReadMore>
      <p>Pete Peterson traced the roots of his character and work ethic back to his birthplace of Kearney, Nebraska. A first-generation American, Pete was born during the Great Depression to Greek immigrant parents who arrived in this country with little education, only a few words of English, and just pennies in their pockets.</p>
      <p>His family operated the quintessential Greek diner &ndash; the Central Caf&eacute; &ndash; which stayed open for literally 24 hours a day, 365 days a year, for 25 straight years. This example taught Pete important lessons about the value of hard work, responsibility and, importantly, saving for the future.</p>
      <p>These lessons stayed with Pete throughout his life, driving his passion to ensure that future generations have the same opportunity to live the American Dream that he did. He saw the rapidly growing national debt as a threat to opportunity, and with good reason.</p>
      <Image
        className="contain--med center-margin"
        src="/tenyearreport/assets/PGP_DownwDeficit.png"
        alt="" /> {/* Poster bw */}
      <p>Across Pete&rsquo;s distinguished and far-reaching career &ndash; which included significant accomplishments across public service, business and philanthropy &ndash; he was a relentless advocate for fiscal responsibility. An author of eight books and dozens of articles, as well as a frequent public speaker, Pete committed himself to educating and persuading voters and policymakers alike about the need to recognize and prepare for America&rsquo;s coming demographic challenges.</p>
      <p>At a fundamental level, Pete saw the nation&rsquo;s fiscal outlook as a threat to the long-term future of America. In 2008, Pete launched the Peter G. Peterson Foundation because, he said, &ldquo;nothing less than the American Dream itself was at stake.&rdquo;</p>
      <Image
        className="contain--med center-margin"
        src={PeterSummit}
        alt="" />
      <Quote>
        “I started the Peter G. Peterson Foundation to engage with people all across America - <span className="highlight-text">policymakers, budget experts, business leaders, academics and citizens of every generation</span> … frankly, anyone who will listen.”
      </Quote>
      <ViewSection download={FounderPdf} />
    </ReadMore>
  </>
)